import request from "@/utils/request";

// 默认表头
const handleX = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
const handleJ = {
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
};
const handleF = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
// const handleQ = {
//   headers: {
//     "Content-Type": "application/json",
//   },
// };

// 自动处理catch，免去每个接口写个没用catch
const HandleType = {
  unHandled: "u",
  then: "t",
  catch: "c",
  finally: "f",
};
class DefaultErrorHandlerPromise {
  constructor(promise, defaultErrorHandler) {
    this.defaultErrorHandler = defaultErrorHandler;
    this.origin = promise;
    promise.catch((err) => {
      if (this.handleType === HandleType.unHandled) {
        this.defaultErrorHandler?.call(null, err);
      }
    });
    this.handleType = HandleType.unHandled;
  }

  then(sucessHandler, errorHandler) {
    if (errorHandler != null) {
      this.handleType |= HandleType.catch;
    }
    if (sucessHandler != null) {
      this.handleType |= HandleType.then;
    }
    const promise = this.origin.then(sucessHandler, errorHandler);
    return new DefaultErrorHandlerPromise(promise, this.defaultErrorHandler);
  }

  catch(errorHandler) {
    this.handleType |= HandleType.catch;
    const promise = this.origin.catch(errorHandler);
    return new DefaultErrorHandlerPromise(promise, this.defaultErrorHandler);
  }

  finally(finallyHandler) {
    this.handleType |= HandleType.finally;
    const promise = this.origin.finally(finallyHandler);
    return new DefaultErrorHandlerPromise(promise, this.defaultErrorHandler);
  }
}

// 默认catch的执行函数
const defaultCatch = (err) => {
  console.log(err);
};

// 接口api------
export const userRegistration = (params) => {
  return new DefaultErrorHandlerPromise(
    request.post("/surveys/user/userRegistration.do", params, handleJ),
    defaultCatch
  );
};

export const userLogin = (params) => {
  return new DefaultErrorHandlerPromise(
    request.post("/surveys/user/userLogin.do", params, handleX),
    defaultCatch
  );
};

export const userReLogin = (params) => {
  return new DefaultErrorHandlerPromise(
    request.post("/surveys/user/userReLogin.do", params, handleX),
    defaultCatch
  );
};

export const returnData = (params) => {
  return new DefaultErrorHandlerPromise(
    request.post("/surveys/returnData.do", params, handleF),
    defaultCatch
  );
};

export const questGoTo = (params) => {
  return new DefaultErrorHandlerPromise(
    request.get("/surveys/questGoTo.do" + params),
    defaultCatch
  );
};

export const getCrmData = (params) => {
  return new DefaultErrorHandlerPromise(
    request.get("/surveys/getCrmData.do", params),
    defaultCatch
  );
};

export const questGroup = (params) => {
  return new DefaultErrorHandlerPromise(
    request.get("/surveys/questGroup.do/" + params),
    defaultCatch
  );
};

export const questSurveyLogData = (params) => {
  return new DefaultErrorHandlerPromise(
    request.post("/surveys/questSurveyLogData.do", params, handleF),
    defaultCatch
  );
};

export const querySurveyListThree = (params) => {
  return new DefaultErrorHandlerPromise(
    request.post("/surveys/querySurveyListThree.do", params, handleF),
    defaultCatch
  );
};

export const querySurveyLogListThree = (params) => {
  return new DefaultErrorHandlerPromise(
    request.post("/surveys/querySurveyLogListThree.do", params, handleF),
    defaultCatch
  );
};

export const getCrmQualification = (params) => {
  return new DefaultErrorHandlerPromise(
    request.post("/surveys/getCrmQualification.do", params, handleF),
    defaultCatch
  );
};

export const updateLog = (params) => {
  return new DefaultErrorHandlerPromise(
    request.post("/surveys/updateLog.do", params, handleX),
    defaultCatch
  );
};

export const updateEndData = (params) => {
  return new DefaultErrorHandlerPromise(
    request.post("/surveys/updateEndData.do", params, handleX),
    defaultCatch
  );
};

export const querySurveyFinance = (params) => {
  return new DefaultErrorHandlerPromise(
    request.post("/surveys/querySurveyFinance.do", params, handleX),
    defaultCatch
  );
};

export const updatePrice = (params) => {
  return new DefaultErrorHandlerPromise(
    request.post("/surveys/user/updatePrice.do", params, handleF),
    defaultCatch
  );
};

export const queryUser = (params) => {
  return new DefaultErrorHandlerPromise(
    request.post("/surveys/user/queryUser.do", params),
    defaultCatch
  );
};
// 手动上架的接口
export const addSurveyStrategy = (params) => {
  return new DefaultErrorHandlerPromise(
    request.post("/surveys/addSurveyStrategy.do", params),
    defaultCatch
  );
};
//手动上架题的攻略
export const showTextBySurveyId = (params) => {
  return new DefaultErrorHandlerPromise(
    request.get("/surveys/showTextBySurveyId.do/?surveyId=" + params),
    defaultCatch
  );
};
