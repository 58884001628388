// 获取ip
export async function getBrowserIP() {
  const response = await fetch("http://ip-api.com/json/");
  const data = await response.json();
  // sessionStorage.setItem("browser_ip", data.query);
  return data.query;
}

// 获取ip地址时间
export async function getTime() {
  //ip找不到会有问题
  let ip = await getBrowserIP();
  const response = await fetch("http://worldtimeapi.org/api/ip/" + ip);
  return await response.json();
}

// 点击事件节流
const pointDoms = []; // 使用这个指令的所有DOM对象
export const points = {
  inserted(el, binding) {
    pointDoms.push(el); // 存储使用这个指令的DOM
    el.addEventListener("click", () => {
      // 禁用所有使用这个指令的DOM结构点击事件
      pointDoms.forEach((pointItem) => {
        pointItem.style.pointerEvents = "none";
      });
      setTimeout(() => {
        // 启动所有使用这个指令的DOM结构点击事件
        pointDoms.forEach((pointItem) => {
          pointItem.style.pointerEvents = "auto";
        });
      }, binding.value || 350);
    });
  },
};

export function _LanguageCodeValue() {
  return [
    { value: "AF", label: "公用荷兰语" },
    { value: "AF-ZA", label: "公用荷兰语-南非" },
    { value: "AR", label: "阿拉伯语" },
    { value: "AR-AE", label: "阿拉伯语-阿拉伯联合酋长国" },
    { value: "AR-BH", label: "阿拉伯语-巴林" },
    { value: "AR-DZ", label: "阿拉伯语-阿尔及利亚" },
    { value: "AR-EG", label: "阿拉伯语-埃及" },
    { value: "AR-IQ", label: "阿拉伯语-伊拉克" },
    { value: "AR-JO", label: "阿拉伯语-约旦" },
    { value: "AR-KW", label: "阿拉伯语-科威特" },
    { value: "AR-LB", label: "阿拉伯语-黎巴嫩" },
    { value: "AR-LY", label: "阿拉伯语-利比亚" },
    { value: "AR-MA", label: "阿拉伯语-摩洛哥" },
    { value: "AR-OM", label: "阿拉伯语-阿曼" },
    { value: "AR-QA", label: "阿拉伯语-卡塔尔" },
    { value: "AR-SA", label: "阿拉伯语-沙特阿拉伯" },
    { value: "AR-SY", label: "阿拉伯语-叙利亚共和国" },
    { value: "AR-TN", label: "阿拉伯语-北非的共和国" },
    { value: "AR-YE", label: "阿拉伯语-也门" },
    { value: "AZ", label: "阿塞拜疆" },
    { value: "AZ-AZ-CYRL", label: "阿塞拜疆语-(西里尔字母)-阿塞拜疆" },
    { value: "AZ-AZ-LATN", label: "阿塞拜疆语-(拉丁文)-阿塞拜疆" },
    { value: "BE", label: "白俄罗斯" },
    { value: "BE-BY", label: "白俄罗斯语-白俄罗斯" },
    { value: "BG", label: "保加利亚" },
    { value: "BG-BG", label: "保加利亚语-保加利亚" },
    { value: "CA", label: "嘉泰罗尼亚" },
    { value: "CA-ES", label: "嘉泰罗尼亚语-嘉泰罗尼亚" },
    { value: "CS", label: "捷克" },
    { value: "CS-CZ", label: "捷克语-捷克" },
    { value: "DA", label: "丹麦" },
    { value: "DA-DK", label: "丹麦语-丹麦" },
    { value: "DE", label: "德国" },
    { value: "DE-AT", label: "德语-奥地利" },
    { value: "DE-CH", label: "德语-瑞士" },
    { value: "DE-DE", label: "德语-德国" },
    { value: "DE-LI", label: "德语-列支敦士登" },
    { value: "DE-LU", label: "德语-卢森堡" },
    { value: "DIV", label: "马尔代夫" },
    { value: "DIV-MV", label: "迪维希语-马尔代夫" },
    { value: "EL", label: "希腊" },
    { value: "EL-GR", label: "希腊语-希腊" },
    { value: "EN", label: "英国" },
    { value: "EN-AU", label: "英语-澳洲" },
    { value: "EN-BZ", label: "英语-伯利兹" },
    { value: "EN-CA", label: "英语-加拿大" },
    { value: "EN-CB", label: "英语-加勒比海" },
    { value: "EN-FR", label: "英语-法国" },
    { value: "EN-GB", label: "英语-英国" },
    { value: "EN-IE", label: "英语-爱尔兰" },
    { value: "EN-IN", label: "英语-印度" },
    { value: "EN-JM", label: "英语-牙买加" },
    { value: "EN-KE", label: "英语-肯尼亚" },
    { value: "EN-NG", label: "英语-尼日利亚" },
    { value: "EN-NZ", label: "英语-新西兰" },
    { value: "EN-PH", label: "英语-菲律宾共和国" },
    { value: "EN-SG", label: "英语-新加坡" },
    { value: "EN-TT", label: "英语-千里达托贝哥共和国" },
    { value: "EN-US", label: "英语-美国" },
    { value: "EN-ZA", label: "英语-南非" },
    { value: "EN-ZW", label: "英语-津巴布韦" },
    { value: "ES", label: "西班牙" },
    { value: "ES-AR", label: "西班牙语-阿根廷" },
    { value: "ES-BO", label: "西班牙语-玻利维亚" },
    { value: "ES-CL", label: "西班牙语-智利" },
    { value: "ES-CO", label: "西班牙语-哥伦比亚" },
    { value: "ES-CR", label: "西班牙语-哥斯达黎加" },
    { value: "ES-DO", label: "西班牙语-多米尼加共和国" },
    { value: "ES-EC", label: "西班牙语-厄瓜多尔" },
    { value: "ES-ES", label: "西班牙语-西班牙" },
    { value: "ES-GT", label: "西班牙语-危地马拉" },
    { value: "ES-HN", label: "西班牙语-洪都拉斯" },
    { value: "ES-MX", label: "西班牙语-墨西哥" },
    { value: "ES-NI", label: "西班牙语-尼加拉瓜" },
    { value: "ES-PA", label: "西班牙语-巴拿马" },
    { value: "ES-PE", label: "西班牙语-秘鲁" },
    { value: "ES-PR", label: "西班牙语-波多黎各" },
    { value: "ES-PY", label: "西班牙语-巴拉圭" },
    { value: "ES-SV", label: "西班牙语-萨尔瓦多" },
    { value: "ES-UY", label: "西班牙语-乌拉圭" },
    { value: "ES-VE", label: "西班牙语-委内瑞拉" },
    { value: "ET", label: "爱沙尼亚" },
    { value: "ET-EE", label: "爱沙尼亚语-爱沙尼亚" },
    { value: "EU", label: "巴斯克" },
    { value: "EU-ES", label: "巴斯克语-巴斯克" },
    { value: "FA", label: "波斯" },
    { value: "FA-IR", label: "波斯语语-伊朗王国" },
    { value: "FI", label: "芬兰" },
    { value: "FI-FI", label: "芬兰语-芬兰" },
    { value: "FIL", label: "菲律宾" },
    { value: "FIL-PH", label: "菲律宾语-菲律宾" },
    { value: "FO", label: "法罗群岛" },
    { value: "FO-FO", label: "法罗群岛语-法罗群岛" },
    { value: "FR", label: "法国" },
    { value: "FR-BE", label: "法语-比利时" },
    { value: "FR-CA", label: "法语-加拿大" },
    { value: "FR-CH", label: "法语-瑞士" },
    { value: "FR-FR", label: "法语-法国" },
    { value: "FR-LU", label: "法语-卢森堡" },
    { value: "FR-MC", label: "法语-摩纳哥" },
    { value: "GL", label: "加利西亚" },
    { value: "GL-ES", label: "加利西亚语-加利西亚" },
    { value: "GU", label: "古吉拉特" },
    { value: "GU-IN", label: "古吉拉特语-印度" },
    { value: "HE", label: "希伯来" },
    { value: "HE-IL", label: "希伯来语-以色列" },
    { value: "HI", label: "北印度" },
    { value: "HI-IN", label: "北印度语-印度" },
    { value: "HR", label: "克罗埃西亚" },
    { value: "HR-HR", label: "克罗埃西亚语-克罗埃西亚" },
    { value: "HU", label: "匈牙利" },
    { value: "HU-HU", label: "匈牙利语-匈牙利" },
    { value: "HY", label: "亚美尼亚" },
    { value: "HY-AM", label: "亚美尼亚语-亚美尼亚" },
    { value: "ID", label: "印尼" },
    { value: "ID-ID", label: "印尼语-印尼" },
    { value: "IS", label: "冰岛" },
    { value: "IS-IS", label: "冰岛语-冰岛" },
    { value: "IT", label: "意大利" },
    { value: "IT-CH", label: "意大利语-瑞士" },
    { value: "IT-IT", label: "意大利语-意大利" },
    { value: "JA", label: "日本" },
    { value: "JA-JP", label: "日本语-日本" },
    { value: "KA", label: "格鲁吉亚州" },
    { value: "KA-GE", label: "格鲁吉亚州语-格鲁吉亚州" },
    { value: "KK", label: "哈萨克" },
    { value: "KK-KZ", label: "哈萨克语-哈萨克" },
    { value: "KN", label: "卡纳达语" },
    { value: "KN-IN", label: "卡纳达语-印度" },
    { value: "KO", label: "韩国" },
    { value: "KO-KR", label: "韩语-韩国" },
    { value: "KOK", label: "孔卡尼" },
    { value: "KOK-IN", label: "孔卡尼语-印度" },
    { value: "KY", label: "吉尔吉斯斯坦" },
    { value: "KY-KZ", label: "吉尔吉斯斯坦语-哈萨克" },
    { value: "LT", label: "立陶宛" },
    { value: "LT-LT", label: "立陶宛语-立陶宛" },
    { value: "LU-LU", label: "卢森堡语-卢森堡" },
    { value: "LV", label: "拉脱维亚" },
    { value: "LV-LV", label: "拉脱维亚语-拉脱维亚" },
    { value: "MK", label: "马其顿" },
    { value: "MK-MK", label: "马其顿语-马其顿共和国" },
    { value: "MN", label: "蒙古" },
    { value: "MN-MN", label: "蒙古语-蒙古" },
    { value: "MR", label: "马拉地" },
    { value: "MR-IN", label: "马拉地语-印度" },
    { value: "MS", label: "马来" },
    { value: "MS-BN", label: "马来语-汶莱" },
    { value: "MS-MY", label: "马来语-马来西亚" },
    { value: "NB-NO", label: "布克莫尔挪威语-挪威" },
    { value: "NL", label: "荷兰" },
    { value: "NL-BE", label: "荷兰语-比利时" },
    { value: "NL-NL", label: "荷兰语-荷兰" },
    { value: "NN-NO", label: "耐诺斯克挪威语-挪威" },
    { value: "NO", label: "挪威" },
    { value: "NO-NO", label: "挪威语-挪威" },
    { value: "PA", label: "旁遮普" },
    { value: "PA-IN", label: "旁遮普语-印度" },
    { value: "PL", label: "波兰" },
    { value: "PL-PL", label: "波兰语-波兰" },
    { value: "PT", label: "葡萄牙" },
    { value: "PT-BR", label: "葡萄牙语-巴西" },
    { value: "PT-PT", label: "葡萄牙语-葡萄牙" },
    { value: "RO", label: "罗马尼亚" },
    { value: "RO-RO", label: "罗马尼亚语-罗马尼亚" },
    { value: "RU", label: "俄国" },
    { value: "RU-RU", label: "俄语-俄国" },
    { value: "SA", label: "梵文" },
    { value: "SA-IN", label: "梵文-印度" },
    { value: "SK", label: "斯洛伐克" },
    { value: "SK-SK", label: "斯洛伐克语-斯洛伐克" },
    { value: "SL", label: "斯洛文尼亚" },
    { value: "SL-SI", label: "斯洛文尼亚语-斯洛文尼亚" },
    { value: "SQ", label: "阿尔巴尼亚" },
    { value: "SQ-AL", label: "阿尔巴尼亚语-阿尔巴尼亚" },
    { value: "SR-SP-CYRL", label: "塞尔维亚语-(西里尔字母)-塞尔" },
    { value: "SR-SP-LATN", label: "塞尔维亚语-(拉丁文)-塞尔维亚共" },
    { value: "SV", label: "瑞典" },
    { value: "SV-FI", label: "瑞典语-芬兰" },
    { value: "SV-SE", label: "瑞典语-瑞典" },
    { value: "SW", label: "斯瓦希里" },
    { value: "SW-KE", label: "斯瓦希里语-肯尼亚" },
    { value: "SYR", label: "叙利亚" },
    { value: "SYR-SY", label: "叙利亚语-叙利亚共和国" },
    { value: "TA", label: "坦米尔" },
    { value: "TA-IN", label: "坦米尔语-印度" },
    { value: "TE", label: "泰卢固" },
    { value: "TE-IN", label: "泰卢固语-印度" },
    { value: "TH", label: "泰国" },
    { value: "TH-TH", label: "泰语-泰国" },
    { value: "TR", label: "土耳其" },
    { value: "TR-TR", label: "土耳其语-土耳其" },
    { value: "TT", label: "鞑靼" },
    { value: "TT-RU", label: "鞑靼语-俄国" },
    { value: "UK", label: "乌克兰" },
    { value: "UK-UA", label: "乌克兰语-乌克兰" },
    { value: "UR", label: "巴基斯坦" },
    { value: "UR-PK", label: "乌尔都语-巴基斯坦" },
    { value: "UZ", label: "乌兹别克" },
    { value: "UZ-UZ-CYRL", label: "乌兹别克语-(西里尔字母的)-乌兹别克" },
    { value: "UZ-UZ-LATN", label: "乌兹别克语-(拉丁文)-乌兹别克斯坦" },
    { value: "VI", label: "越南" },
    { value: "VI-VN", label: "越南语-越南" },
    { value: "ZH-CHS", label: "华语-(单一化)" },
    { value: "ZH-CHT", label: "华语-(传统)" },
    { value: "ZH-CN", label: "华语-中国" },
    { value: "ZH-HK", label: "华语-香港" },
    { value: "ZH-MO", label: "华语-澳门" },
    { value: "ZH-SG", label: "华语-新加坡" },
    { value: "ZH-TW", label: "华语-台湾" },
  ];
}
export function _getCurrentTime(date) {
  const Y = date.getFullYear() + "-";
  const M = (date.getMonth() + 1).toString().padStart(2, "0") + "-";
  const D = date.getDate().toString().padStart(2, "0") + " ";
  const h = date.getHours().toString().padStart(2, "0") + ":";
  const m = date.getMinutes().toString().padStart(2, "0") + ":";
  const s = date.getSeconds().toString().padStart(2, "0");
  return Y + M + D + h + m + s;
}
