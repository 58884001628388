<template>
  <div>
    <MoonBackground>
      <div class="ve_container">
        <el-card class="box-card">
          <h1>Survey System</h1>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="ruleForm"
          >
            <el-form-item prop="user_name">
              <el-input
                v-model="ruleForm.user_name"
                placeholder="用户名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="user_password">
              <el-input
                v-model="ruleForm.user_password"
                placeholder="密码"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm('ruleForm')"
                style="width: 100%"
                >登录
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </MoonBackground>
  </div>
</template>

<script>
import MoonBackground from "@/components/MoonBackground.vue";
import { userLogin, userReLogin } from "@/assets/api/index";

export default {
  components: {
    MoonBackground,
  },
  data() {
    return {
      ruleForm: {
        user_name: process.env.VUE_APP_USER_NAME || "", //test@test.com
        user_password: process.env.VUE_APP_USER_PASSWORD || "", //qwfqwf123
      },
      rules: {
        user_name: [
          {
            required: true,
            type: "email",
            message: "请输入正确的邮箱格式",
            trigger: "blur",
          },
        ],
        user_password: [
          {
            required: true,
            type: "string",
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          userLogin(this.ruleForm)
            .then((value) => {
              localStorage.setItem("userInfo", JSON.stringify(value.data));
              localStorage.setItem("token", value.token);
              this.$router.push("/dashboard");
            })
            .catch(() => {
              this.$message.error("登录失败");
            });
        } else {
          return false;
        }
      });
    },
  },
  created() {
    if (
      !!this.$route.query.uid &&
      !!this.$route.query.pid &&
      !!this.$route.query.status
    )
      return this.$router.push({ path: "/callback", query: this.$route.query });
    if (localStorage.getItem("token")) {
      userReLogin({
        user_token: localStorage.getItem("token"),
      })
        .then((value) => {
          localStorage.setItem("userInfo", JSON.stringify(value.data));
          localStorage.setItem("token", value.token);
          this.$router.push("/dashboard");
        })
        .catch(() => {
          this.$message.error("自动登录失败");
        });
    }
  },
};
</script>

<style lang="less" scoped>
.ve_container {
  position: absolute;
  width: 400px;
  top: 50%;
  left: 100px;
  transform: translateY(-50%);
  min-height: 273px;
  text-align: center;
  z-index: 3;
  .box-card {
    h1 {
      font-size: 36px;
      transition-duration: 1s;
      transition-timing-function: ease-in-put;
      font-weight: 400;
      margin-top: 12px;
    }
    /deep/ .el-card__body {
      padding: 0;
      background: rgba(0, 0, 0, 0.15);
      padding: 20px;
      .ruleForm {
        .el-input__icon {
          user-select: none;
        }
      }
    }
  }
}
</style>
