<template>
  <div class="ve_404">
    <div class="moon"></div>
    <div class="moon__crater moon__crater1"></div>
    <div class="moon__crater moon__crater2"></div>
    <div class="moon__crater moon__crater3"></div>
    <div class="star star1">⭐</div>
    <div class="star star2">⭐</div>
    <div class="star star3">⭐</div>
    <div class="star star4">⭐</div>
    <div class="star star5">⭐</div>
    <slot></slot>
    <div class="astronaut">
      <div class="astronaut__backpack"></div>
      <div class="astronaut__body"></div>
      <div class="astronaut__body__chest"></div>
      <div class="astronaut__arm-left1"></div>
      <div class="astronaut__arm-left2"></div>
      <div class="astronaut__arm-right1"></div>
      <div class="astronaut__arm-right2"></div>
      <div class="astronaut__arm-thumb-left"></div>
      <div class="astronaut__arm-thumb-right"></div>
      <div class="astronaut__leg-left"></div>
      <div class="astronaut__leg-right"></div>
      <div class="astronaut__foot-left"></div>
      <div class="astronaut__foot-right"></div>
      <div class="astronaut__wrist-left"></div>
      <div class="astronaut__wrist-right"></div>
      <div class="astronaut__head">
        <div class="astronaut__head-visor-face"></div>
        <div class="astronaut__head-visor-flare1"></div>
        <div class="astronaut__head-visor-flare2"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MoonBackground",
};
</script>
<style lang="less" scoped>
.ve_404 {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, #2f3640 23%, #181b20);
}
.moon {
  background: linear-gradient(90deg, #d0d0d0 48%, #919191);
  position: absolute;
  top: -30vh;
  left: -80vh;
  width: 160vh;
  height: 160%;
  content: "";
  border-radius: 50%;
  box-shadow: 0 0 30px -4px rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.moon__crater {
  position: absolute;
  content: "";
  border-radius: 100%;
  background: linear-gradient(90deg, #7a7a7a 38%, #c3c3c3);
  opacity: 0.6;
  z-index: 1;
}
.moon__crater1 {
  top: 250px;
  left: 500px;
  width: 60px;
  height: 180px;
}
.moon__crater2 {
  top: 650px;
  left: 340px;
  width: 40px;
  height: 80px;
  transform: rotate(55deg);
}
.moon__crater3 {
  top: -20px;
  left: 40px;
  width: 65px;
  height: 120px;
  transform: rotate(250deg);
}
.star {
  color: grey;
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  border-radius: 100%;
  transform: rotate(250deg);
  opacity: 0.4;
  -webkit-animation-name: shimmer-15786058;
  animation-name: shimmer-15786058;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  z-index: 0;
}
@-webkit-keyframes shimmer-15786058 {
  0% {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}
@keyframes shimmer-15786058 {
  0% {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}
.star1 {
  top: 40%;
  left: 50%;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.star2 {
  top: 60%;
  left: 90%;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.star3 {
  top: 10%;
  left: 70%;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.star4 {
  top: 90%;
  left: 40%;
}
.star5 {
  top: 20%;
  left: 30%;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.astronaut {
  position: absolute;
  width: 185px;
  height: 300px;
  left: 70%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(20deg);
  z-index: 2;
}
.astronaut__head {
  background-color: #fff;
  position: absolute;
  top: 60px;
  left: 60px;
  width: 60px;
  height: 60px;
  content: "";
  border-radius: 2em;
  overflow: hidden;
}
.astronaut__head .astronaut__head-visor-face {
  position: relative;
  left: 6px;
  top: 10px;
  border-radius: 10px;
  background: #2f3640;
  width: 48px;
  height: 100%;
}
.astronaut__head-visor-flare1 {
  background-color: #7f8fa6;
  position: absolute;
  top: 28px;
  left: 40px;
  width: 10px;
  height: 10px;
  content: "";
  border-radius: 2em;
  opacity: 0.5;
}
.astronaut__head-visor-flare2 {
  background-color: #718093;
  position: absolute;
  top: 40px;
  left: 38px;
  width: 5px;
  height: 5px;
  content: "";
  border-radius: 2em;
  opacity: 0.3;
}
.astronaut__backpack {
  background-color: #bfbfbf;
  position: absolute;
  top: 90px;
  left: 47px;
  width: 86px;
  height: 90px;
  content: "";
  border-radius: 8px;
}
.astronaut__body {
  background-color: #e6e6e6;
  position: absolute;
  top: 115px;
  left: 55px;
  width: 70px;
  height: 80px;
  content: "";
  border-radius: 8px;
}
.astronaut__body__chest {
  background-color: #d9d9d9;
  position: absolute;
  top: 140px;
  left: 68px;
  width: 45px;
  height: 25px;
  content: "";
  border-radius: 6px;
}
.astronaut__arm-left1 {
  background-color: #e6e6e6;
  position: absolute;
  top: 127px;
  left: 9px;
  width: 65px;
  height: 20px;
  content: "";
  border-radius: 8px;
  transform: rotate(-30deg);
}
.astronaut__arm-left2 {
  background-color: #e6e6e6;
  position: absolute;
  top: 102px;
  left: 7px;
  width: 20px;
  height: 45px;
  content: "";
  border-radius: 8px;
  transform: rotate(-12deg);
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
}
.astronaut__arm-right1 {
  top: 113px;
  left: 100px;
  width: 65px;
  height: 20px;
}
.astronaut__arm-right1,
.astronaut__arm-right2 {
  background-color: #e6e6e6;
  position: absolute;
  content: "";
  border-radius: 8px;
  transform: rotate(-10deg);
}
.astronaut__arm-right2 {
  top: 78px;
  left: 141px;
  width: 20px;
  height: 45px;
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
}
.astronaut__arm-thumb-left {
  top: 110px;
  left: 21px;
  transform: rotate(-35deg);
}
.astronaut__arm-thumb-left,
.astronaut__arm-thumb-right {
  background-color: #e6e6e6;
  position: absolute;
  width: 10px;
  height: 6px;
  content: "";
  border-radius: 8em;
}
.astronaut__arm-thumb-right {
  top: 90px;
  left: 133px;
  transform: rotate(20deg);
}
.astronaut__wrist-left {
  top: 122px;
  left: 6.5px;
  transform: rotate(-15deg);
}
.astronaut__wrist-left,
.astronaut__wrist-right {
  background-color: #409eff;
  position: absolute;
  width: 21px;
  height: 4px;
  content: "";
  border-radius: 8em;
}
.astronaut__wrist-right {
  top: 98px;
  left: 141px;
  transform: rotate(-10deg);
}
.astronaut__leg-left {
  left: 50px;
  transform: rotate(10deg);
}
.astronaut__leg-left,
.astronaut__leg-right {
  background-color: #e6e6e6;
  position: absolute;
  top: 188px;
  width: 23px;
  height: 75px;
  content: "";
}
.astronaut__leg-right {
  left: 108px;
  transform: rotate(-10deg);
}
.astronaut__foot-left {
  left: 43px;
  transform: rotate(10deg);
}
.astronaut__foot-left,
.astronaut__foot-right {
  background-color: #fff;
  position: absolute;
  top: 240px;
  width: 28px;
  height: 20px;
  content: "";
  border-radius: 3px;
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
  border-bottom: 4px solid #409eff;
}
.astronaut__foot-right {
  left: 111px;
  transform: rotate(-10deg);
}
</style>
