import Vue from "vue";
import VueRouter from "vue-router";
import LayoutHome from "../views/layout/LayoutHome.vue";
import UserLogin from "../views/UserLogin.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "home",
    component: LayoutHome,
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/HomeVisualization.vue"),
        meta: { title: "Dashboard" },
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import("../views/AccountSetting.vue"),
        meta: { title: "账户设置" },
      },
      {
        path: "/surveylist",
        name: "surveylist",
        component: () => import("../views/SurveyList.vue"),
        meta: { title: "调查列表" },
      },
      {
        path: "/surveyrecord",
        name: "surveyrecord",
        component: () => import("../views/SurveyRecord.vue"),
        meta: { title: "调查记录" },
      },
      {
        path: "/financiallog",
        name: "financiallog",
        component: () => import("../views/FinancialLog.vue"),
        meta: { title: "财务日志" },
      },
      {
        path: "/userlist",
        name: "userlist",
        component: () => import("../views/UserList.vue"),
        meta: { title: "所有用户" },
      },
      {
        path: "/addsurvey",
        name: "addsurvey",
        component: () => import("../views/AddSurveyData.vue"),
        meta: { title: "手动上架" },
      },
    ],
  },
  {
    path: "/",
    name: "login",
    component: UserLogin,
    meta: { title: "登录" },
  },
  {
    path: "/callback",
    name: "callback",
    component: () => import("../views/CallBack.vue"),
    meta: { title: "回调" },
  },
  {
    path: "/404",
    name: "PageStatus404",
    component: () => import("../views/PageStatus404.vue"),
    meta: { title: "登录" },
  },
  { path: "*", redirect: "/404" },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (localStorage.getItem("token")) {
    next();
  } else {
    if (to.name == "login" || to.name == "callback") {
      next(); // 跳出循环
    } else {
      next({ path: "/" }); // 否则全部重定向到登录页
    }
  }
});

export default router;
