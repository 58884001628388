<template>
  <div class="layout-aside">
    <el-menu
      :default-active="name"
      :class="['el-menu-vertical-demo', { show: is_show }]"
      background-color="#304156"
      text-color="#bfcbd9"
      active-text-color="#409eff"
      style="height: 100%"
      :collapse="is_unfold"
      router
    >
      <el-menu-item index="Dashboard" route="dashboard" class="menu-item">
        <i class="el-icon-location"></i>
        <span slot="title">Dashboard</span>
      </el-menu-item>
      <el-menu-item index="账户设置" route="settings" class="menu-item">
        <i class="el-icon-setting"></i>
        <span slot="title">账户设置</span>
      </el-menu-item>
      <el-menu-item index="调查列表" route="surveylist" class="menu-item">
        <i class="el-icon-document"></i>
        <span slot="title">调查列表</span>
      </el-menu-item>
      <el-menu-item index="调查记录" route="surveyrecord" class="menu-item">
        <i class="el-icon-tickets"></i>
        <span slot="title">调查记录</span>
      </el-menu-item>
      <el-menu-item index="财务日志" route="financiallog" class="menu-item">
        <i class="el-icon-data-line"></i>
        <span slot="title">财务日志</span>
      </el-menu-item>
      <el-menu-item
        v-if="authority == 1"
        index="所有用户"
        route="userlist"
        class="menu-item"
      >
        <i class="el-icon-user"></i>
        <span slot="title">所有用户</span>
      </el-menu-item>
      <el-menu-item
        v-if="authority == 1"
        index="手动上架"
        route="addsurvey"
        class="menu-item"
      >
        <i class="el-icon-reading"></i>
        <span slot="title">手动上架</span>
      </el-menu-item>
    </el-menu>
    <el-drawer
      direction="ltr"
      :visible.sync="is_unfold_drawer"
      :with-header="false"
      size="200px"
    >
      <el-menu
        :default-active="name"
        class="el-menu-vertical-demo"
        background-color="#304156"
        text-color="#bfcbd9"
        active-text-color="#409eff"
        style="height: 100%"
        router
      >
        <el-menu-item index="Dashboard" route="dashboard" class="menu-item">
          <i class="el-icon-location"></i>
          <span slot="title">Dashboard</span>
        </el-menu-item>
        <el-menu-item index="账户设置" route="settings" class="menu-item">
          <i class="el-icon-setting"></i>
          <span slot="title">账户设置</span>
        </el-menu-item>
        <el-menu-item index="调查列表" route="surveylist" class="menu-item">
          <i class="el-icon-document"></i>
          <span slot="title">调查列表</span>
        </el-menu-item>
        <el-menu-item index="调查记录" route="surveyrecord" class="menu-item">
          <i class="el-icon-tickets"></i>
          <span slot="title">调查记录</span>
        </el-menu-item>
        <el-menu-item index="财务日志" route="financiallog" class="menu-item">
          <i class="el-icon-data-line"></i>
          <span slot="title">财务日志</span>
        </el-menu-item>
        <el-menu-item
          v-if="authority == 1"
          index="所有用户"
          route="userlist"
          class="menu-item"
        >
          <i class="el-icon-user"></i>
          <span slot="title">所有用户</span>
        </el-menu-item>
        <el-menu-item
          v-if="authority == 1"
          index="手动上架"
          route="addsurvey"
          class="menu-item"
        >
          <i class="el-icon-reading"></i>
          <span slot="title">手动上架</span>
        </el-menu-item>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "LayoutAside",
  props: ["is_unfold", "is_show"],
  data() {
    return {
      name: this.$route.name,
      windowWidth: document.body.clientWidth,
      is_unfold_drawer: false,
      authority: JSON.parse(localStorage.getItem("userInfo")).authority,
    };
  },
  watch: {},
  methods: {},
  created() {
    this.name = this.$route.meta.title;
    this.$EventBus.$on("isUnfoldDrawer", () => {
      this.is_unfold_drawer = true;
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("isUnfoldDrawer");
  },
};
</script>
<style lang="less" scoped>
.layout-aside {
  height: 100%;
  .el-menu-vertical-demo {
    display: block;
    &:not(.el-menu--collapse) {
      width: 200px;
      min-height: 400px;
      border: 0;
    }
    .menu-item {
      user-select: none;
    }
  }
  .show {
    // @media (max-width: 1100px) {
    //   display: none;
    // }
    display: none;
  }
}
</style>
