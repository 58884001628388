<template>
  <div class="header">
    <div>
      <div class="icon" @click="isUnfold">
        <i :class="is_unfold ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
      </div>
      <el-breadcrumb class="breadcrumb" separator="/" style="color: #fff">
        <transition-group name="breadcrumb">
          <el-breadcrumb-item class="breadcrumb-item" key="breadcrumb">
            Dashboard
          </el-breadcrumb-item>
          <el-breadcrumb-item
            class="breadcrumb-item"
            v-if="title != 'Dashboard' && title != ''"
            :key="title"
            >{{ title }}
          </el-breadcrumb-item>
        </transition-group>
      </el-breadcrumb>
    </div>
    <span class="welcome-text">
      亲爱的{{ userInfo.authority == 1 ? "超级用户" : "普通用户" }}：{{
        userInfo.name
      }}
    </span>
    <div style="margin-right: 50px">
      <i class="el-icon-switch-button icon" @click="logOut"></i>
      <!-- <i class="el-icon-s-tools icon"></i>
      <i class="el-icon-s-tools icon"></i> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "LayoutHeader",
  data() {
    return {
      title: this.$route.meta.title,
      is_unfold: false,
      windowWidth: document.body.clientWidth,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
    };
  },
  watch: {
    $route() {
      this.title = this.$route.meta.title;
    },
    windowWidth(val) {
      this.isShow(val);
    },
  },
  methods: {
    logOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      this.$router.push("/");
    },
    isUnfold() {
      if (this.windowWidth < 1100) {
        this.is_unfold = true;
        this.$EventBus.$emit("isUnfoldDrawer");
      } else {
        this.is_unfold = !this.is_unfold;
        this.$emit("isUnfold", this.is_unfold);
      }
    },
    isShow(val) {
      if (val < 1100) {
        this.is_unfold = true;
        this.$emit("isUnfold", true);
        this.$emit("isShow", true);
      } else {
        // this.is_unfold = false;
        // this.$emit("isUnfold", false);
        this.$emit("isShow", false);
      }
    },
  },
  created() {
    this.isShow(window.innerWidth);
    window.onresize = () => {
      return (() => {
        this.windowWidth = document.documentElement.clientWidth;
      })();
    };
  },
};
</script>
<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .icon {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    transition: background 0.3s;
    display: inline-block;
    &:hover {
      background-color: rgba(50, 50, 50, 0.05);
    }
    &:first-child {
      i {
        margin-top: 13px;
      }
    }
  }
  i {
    font-size: 24px;
  }
  .breadcrumb {
    display: inline-block;
    .breadcrumb-item {
      font-size: 16px;
    }
  }
  .welcome-text {
    @media (max-width: 920px) {
      display: none;
    }
  }
  .breadcrumb-enter-active {
    transition: all 0.4s;
  }
  .breadcrumb-leave-active {
    transition: all 0.3s;
  }
  .breadcrumb-enter,
  .breadcrumb-leave-active {
    opacity: 0;
    transform: translateX(20px);
  }
  .breadcrumb-leave-active {
    position: absolute;
  }
}
</style>
