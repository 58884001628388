<template>
  <el-container style="height: 100%">
    <el-aside width="none" height="100%">
      <LayoutAside :is_unfold="is_unfold" :is_show="is_show" />
    </el-aside>
    <el-container>
      <el-header height="50px" style="padding: 0">
        <LayoutHeader @isUnfold="isUnfold" @isShow="isShow" />
      </el-header>
      <el-main><router-view /></el-main>
    </el-container>
  </el-container>
</template>

<script>
import LayoutAside from "./LayoutAside.vue";
import LayoutHeader from "./LayoutHeader.vue";
export default {
  name: "LayoutHome",
  components: {
    LayoutAside,
    LayoutHeader,
  },
  data() {
    return {
      is_unfold: false,
      is_show: false,
    };
  },
  watch: {},
  methods: {
    isUnfold(val) {
      this.is_unfold = val;
    },
    isShow(val) {
      setTimeout(() => {
        this.is_show = val;
      }, 300);
    },
  },
};
</script>
<style lang="less" scoped>
.el-header {
  caret-color: transparent;
}
.el-aside {
  transition: all 300ms;
}
.el-main {
  /* padding: 0; */
}
</style>
