import axios from "axios";
import router from "../router";
import { Message } from "element-ui";

const request = axios.create({
  withCredentials: true,
  // baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000,
  retry: 3,
  retryDelay: 1000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json;charset=utf-8",
    // Token: localStorage.getItem("token"),
  },
});

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(
  (config) => {
    config.headers.Token = localStorage.getItem("token");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
  (response) => {
    let res = response.data;
    if (res.code == 401 && router.currentRoute.name != "login") {
      localStorage.removeItem("token");
      router.push({ name: "login" });
      Message.error("登录过期，请重新登录");
    }
    if (res.code != 200) return Promise.reject(new Error(res.message));
    // 如果是返回的文件
    if (response.config.responseType === "blob") {
      return res;
    }
    // 兼容服务端返回的字符串数据
    if (typeof res === "string") {
      res = res ? JSON.parse(res) : res;
    }
    return res;
  },
  (error) => {
    // console.log("err" + error); // for debug
    // return Promise.reject(error);

    //console.log(error);
    //超时处理 error.config是一个对象，包含上方create中设置的三个参数
    var config = error.config;
    if (!config || !config.retry) return Promise.reject(error);

    // __retryCount用来记录当前是第几次发送请求
    config.__retryCount = config.__retryCount || 0;

    // 如果当前发送的请求大于等于设置好的请求次数时，不再发送请求，返回最终的错误信息
    if (config.__retryCount >= config.retry) {
      // return Promise.reject(error);
      Message.error("网络异常，请稍后再试");
      return Promise.reject(error);
    }

    // 记录请求次数+1
    config.__retryCount += 1;

    // 设置请求间隔 在发送下一次请求之前停留一段时间，时间为上方设置好的请求间隔时间
    var backoff = new Promise(function (resolve) {
      setTimeout(function () {
        resolve();
      }, config.retryDelay || 1);
    });

    // 再次发送请求
    return backoff.then(function () {
      return request(config);
    });
  }
);

export default request;
